html,
body {
	font-size: 16px;
	height: 100%;
	margin: 0;
}

body {
	background: black;
	overflow-y: scroll;
	font-family: 'protofont',Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

#root {
	position: relative;
	height: 100%;
	margin: 0;
}

.hero {
	padding-top: 1.5rem;
	margin-bottom: 1.5rem;
}

.hero .logo-long {
	max-width: 100%;
	height: 3rem;
	display: block;
	margin: auto;
}

.hero a {
	outline: none;
}

#ps-menu .menu .item {
	padding: 1.25rem 1rem !important;
}

#ps-menu .dropdown.icon {
	display: none;
}

.topPadding {
	padding-top: 1rem;
}

.topPadding > .ui.container {
	min-height: 45rem;
}

.bean,
.photo-404 {
	max-width: 100%;
	height: auto;
}

.swordfish {
	width: 100%;
	margin-top: -1rem;
}

.content-wrap {
	min-height: 100%;
	background: white;
}

.content-wrap-inside {
	padding-bottom: 24rem;
}

.course-editor,
.class-editor,
.transaction-editor {
	margin-bottom: 1rem;
}

.ql-container {
	height: 30rem !important;
	font-size: inherit !important;
	font-family: inherit !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	margin-bottom: 1rem !important;
}

.rdtTimeToggle {
	font-size: 2rem;
	padding-top: 1rem;
}

.ui.floating.dropdown.button.icon {
	border-left: 1px;
	border-left-color: #c0c1c2;
	border-left-style: solid;
}

.attendance-row {
	margin-top: 1rem;
}

.attendance-row .button {
	margin-bottom: 4px;
}

.pay-custom {
	margin-bottom: 1rem;
	width: 10rem;
}

.bio-paragraph {
	white-space: pre-line;
	margin-top: -1rem;
	margin-left: 1rem;
}

.list-num {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	color: black;
}

.chartstats .ui.statistic {
	margin: 0 1.5em 1em !important;
}

.coursetags .ui.tag.label {
	margin-bottom: 1rem;
}

.newclasstable {
	margin: 0 -1.5rem 0 -0.5rem;
	display: flex;
	flex-wrap: wrap;
}

.newclasstable .byline {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.newclasstable .byline .interest {
	display: inline;
	margin-left: auto;
}

.interest .ui.button {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.ui.tag.label {
	padding-left: 1rem;
	padding-right: 0.5rem;
}

.labelbox {
	display: inline;
	padding: 0.55rem 0.1rem;
	border: 2px solid black;
}

.display {
	display: flex;
	justify-content: space-between;
	height: 100vh;
	background-color: black;
	color: white;
	font-size: 1.8em;
}

.display-usage {
	border: 1px solid white;
	padding: 0.5em;
	align-self: flex-end;
	width: 25vw;
	height: 75vh;
}

.display-scores {
	border: 1px solid white;
	padding: 0.5em;
	align-self: flex-end;
	width: 25vw;
	height: 75vh;
}

.display-scores p {
	font-size: 1.5em;
	text-align: right;
}

.usage {
	height: 100vh;
	background-color: black;
	color: white;
	padding: 0.5em;
	font-size: 2.5em;
}

.display .ui.header,
.usage .ui.header {
	color: white;
	margin-top: 0.5em;
	margin-bottom: 0em;
}

.display .stat,
.usage .stat {
	font-size: 2em;
	margin-bottom: 0;
}

.nowrap-stat {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


.footer {
	margin-top: -20rem;
	background: black;
	color: white;
	display: grid;
}

@media (min-width: 650px) {
	.footer-content {
		transition: all 0.2s ease-in;
		opacity: 0.5;
	}

	.footer-content:hover {
		opacity: 1;
	}
}

/* grid bottom */
.footer canvas {
	grid-row: 1/2;
	grid-column: 1/2;
	margin: auto;
}

/* grid top */
.footer .container {
	z-index: 2;
	grid-row: 1/2;
	grid-column: 1/2;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.footer p {
	color: white;
}

.footer .logo {
	filter: invert(100%);
	height: 2rem;
}

.footer a {
	color: white;
	outline: 0;
}

.footer .text a {
	border-bottom: 1px solid #ddd;
	padding-bottom: 2px;
}

.footer i {
	margin-top: 3px;
	margin-left: -3.5px;
	margin-right: 0.5em;
}

.darkmode-layer,
.darkmode-toggle {
	z-index: 500;
}

.darkmode--activated .ui.image {
	mix-blend-mode: difference;
	filter: brightness(50%);
}

.darkmode--activated i.green.circle.icon,
.darkmode--activated i.yellow.circle.icon,
.darkmode--activated i.red.circle.icon {
	mix-blend-mode: difference;
}

.darkmode--activated .footer {
	mix-blend-mode: difference;
}
